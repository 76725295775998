import React from "react"

import Layout from "../components/layout"
import Posts from "../components/posts"
import SEO from "../components/seo"
import H1 from "../components/h1"

const title = "Wolne Miejsca"

const WolneTerminy = () => (
    <Layout>
        <SEO title={title} />
        <H1 title={title} page="page"></H1>
        <Posts category="wolne-terminy" />
    </Layout>
)

export default WolneTerminy
